import React, { useEffect } from 'react';

import cookie from 'cookie';
import { Persistors, SSRKeycloakProvider } from '@react-keycloak/nextjs';
import TagManager from 'react-gtm-module'

import App from 'next/app';
import Script from 'next/script';
import { DefaultSeo } from 'next-seo';
import SEO from '../../next-seo.config';
import '../styles/globals.css';

const keycloakCfg = {
    url: process.env.KEYCLOAK_URL,
    realm: process.env.KEYCLOAK_REALM,
    clientId: process.env.KEYCLOAK_CLIENT_ID,
};

const tagManagerArgs = process.env.GTM_ID? { gtmId: process.env.GTM_ID } : null;

function MyApp(props) {
    const { Component, pageProps, cookies, origin } = props;

    useEffect(() => {
        const jssStyles = document.querySelector('#jss-server-side');

        if (jssStyles && jssStyles.parentNode) {
            jssStyles.parentNode.removeChild(jssStyles);
        }

        if (tagManagerArgs) {
            TagManager.initialize(tagManagerArgs);
          }    
    }, []);

    return (
        <React.Fragment>
            <DefaultSeo {...SEO} />

            <SSRKeycloakProvider
                keycloakConfig={keycloakCfg}
                initConfig={{
                    onLoad: 'check-sso',
                    silentCheckSsoRedirectUri: origin
                        ? origin + '/silent-check-sso.html'
                        : undefined,
                }}
                persistor={Persistors.Cookies(cookies)}
            >
                <Script src="https://unpkg.com/swiper@8/swiper-bundle.min.js" />
                <Component {...pageProps} />
            </SSRKeycloakProvider>
        </React.Fragment>
    );
}

function parseCookies(req) {
    if (!req || !req.headers) {
        return {};
    }

    return cookie.parse(req.headers.cookie || '');
}

MyApp.getInitialProps = async (context) => {
    let origin;

    if (process.env.BASE_URL) {
        origin = process.env.BASE_URL;
    } else if (typeof typeof window !== 'undefined') {
        origin = window.location.origin;
    }
    const appProps = await App.getInitialProps(context);

    return {
        ...appProps,
        cookies: parseCookies(context?.ctx?.req),
        origin: origin,
    };
};

export default MyApp;
