/* eslint-disable import/no-anonymous-default-export */
const ClassiLogo = '/svg/logo-classi.svg';

export default {
    defaultTitle:
        'Casas, apartamentos e imóveis para alugar ou à venda - Classi',
    titleTemplate: '%s',
    openGraph: {
        type: 'website',
        url: process.env.BASE_URL,
        site_name: 'Classi',
        images: [{ url: process.env.BASE_URL + ClassiLogo }],
    },
    twitter: {
        handle: '@classi_br',
        site: '@classi_br',
        cardType: 'summary',
    },
    facebook: {
        appId: '177140826180797',
    },
    additionalMetaTags: [
        {
            property: 'url',
            content: process.env.BASE_URL,
        },
        {
            property: 'type',
            content: 'website',
        },
        {
            property: 'image',
            content: process.env.BASE_URL + ClassiLogo,
        },
        {
            property: 'site_name',
            content: 'Classi',
        },
        {
            property: 'fb:admins',
            content: '177140826180797',
        },
        {
            property: 'title',
            content:
                'Casas, apartamentos e imóveis para alugar ou à venda - Classi',
        },
    ],
};
